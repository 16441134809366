const CustomUIComponents = (editor) => {
	const icons = {
		accordion:
			'https://storage.googleapis.com/liii/contentImages/0adfe8ebf90f26238cba3bf42fdabb0edGSktzzDS7gzPwKn8fm9.png',
		tabs:
			'https://storage.googleapis.com/liii/contentImages/23add4b50239481903426ee7f11f042bJabMRy5sTcFGwk9F6FVw.png',
		table:
			'https://storage.googleapis.com/liii/contentImages/c92714d93a755dffe5275c29eb125ca1sFzgmJG3Eq65Th5PJbRf.png',
		card:
			'https://storage.googleapis.com/liii/contentImages/6d634d1da3ded04350d4b20ccbca392aKQujM4TknHvGZZaSpkrV.png',
		carousel:
			'https://storage.googleapis.com/liii/contentImages/c0fc962576a62002a330dc890799e73fKXGyDVUkbFyFAr2usH9d.png',
		navbar:
			'https://storage.googleapis.com/liii/contentImages/c629ca7e0e4d3929b5c9836cb9bc9ff4Mgrv9UxeTn83pBy3saXy.png',
		imageRight:
			'https://storage.googleapis.com/liii/contentImages/801ca0aec2f2527ef430a732717fb82eHthYtWu552YbBbfWm78d.png',
		imageLeft:
			'https://storage.googleapis.com/liii/contentImages/295891e93ece1d8c5b3522869573d485U8dJXbWHVszjNKYSbHds.png',
		features:
			'https://storage.googleapis.com/liii/contentImages/cb0e5c6a73f89eff81f4d8f0a0ffe2893fa2jKsPAvVBNqbknKqG.png',
		team:
			'https://storage.googleapis.com/liii/contentImages/8cf700c5978472c138b6e1784e794369cXsMrjCeNeaADmd38dx5.png',
		hero:
			'https://storage.googleapis.com/liii/contentImages/598e708cb9d576a2878813769214b133QqzfyeDKsnAHpentCG2y.png',
		pricing:
			'https://storage.googleapis.com/liii/contentImages/71a35e099d96d23509bb5e39840fcc595A6M7AJTe579yFnZEsT4.png',
		imageIntro:
			'https://storage.googleapis.com/liii/contentImages/5f87a6538a5fa7958fd4cc0725d3d8c4uFxvb5xbmTJfAm4ZqfSj.png',

		counter:
			'https://storage.googleapis.com/liii/contentImages/bbe3ec2ad9432d3d46693084e08743abbrX7JeJdjFy42d6R8rAt.png',
		faq:
			'https://storage.googleapis.com/liii/contentImages/c50d5bc1bb1ce766a7e3fe19b9080db4kxJnqcn6ANKnFmBTN2U4.png',
	};

	const componentsWithCount = [
		'accordion',
		'tabs',
		'carousel',
		'features',
		'counter',
		'team',
		'pricing',
		'faq',
		'imageIntro',
	];

	const openDialog = function () {
		let selectedComponent = 'accordion';
		let itemCount = '3';

		const updateHtmlPanel = (api) => {
			const selected = api.getData().selectedComponent;
			const count = api.getData()?.itemCount || itemCount;
			selectedComponent = selected;
			itemCount = count;
			html = generateHtml(selectedComponent);
			api.redial(getDialogConfig(html, selectedComponent, itemCount));
		};

		const generateHtml = (component) => {
			return `<div style="height: 200px; display: flex; justify-content: center; align-items: center;">
                ${
					icons[component]
						? `<img src="${icons[component]}" alt="${component}" style="max-width: 100%; max-height: 100%; object-fit: contain;">`
						: 'Select a component to preview'
				}
            </div>`;
		};

		let html = generateHtml(selectedComponent);

		const getDialogConfig = (html, selectedComponent, itemCount) => {
			const dialogItems = [
				{
					type: 'selectbox',
					name: 'selectedComponent',
					label: 'Select a component',
					items: Object.keys(icons).map((key) => ({
						value: key,
						text: key.charAt(0).toUpperCase() + key.slice(1),
					})),
				},
				{
					type: 'htmlpanel',
					html: html,
				},
			];

			// Add number input only for components that need it
			if (componentsWithCount.includes(selectedComponent)) {
				dialogItems.push({
					type: 'input',
					name: 'itemCount',
					label: 'Number of items',
					items: [],
				});
			}

			return {
				title: 'Insert UI Component',
				size: 'small',
				body: {
					type: 'panel',
					items: dialogItems,
				},
				buttons: [
					{
						type: 'submit',
						text: 'Insert',
						primary: true,
					},
					{
						type: 'cancel',
						text: 'Close',
					},
				],
				onSubmit: function (api) {
					const data = api.getData();
					const selectedComponent = data.selectedComponent;
					const count = parseInt(data.itemCount) || 3; // Use 3 as default if not provided
					if (selectedComponent) {
						insertComponent(selectedComponent, count);
					}
					api.close();
				},
				onChange: function (api, details) {
					if (details.name === 'selectedComponent') {
						updateHtmlPanel(api);
					}
				},
				initialData: {
					selectedComponent: selectedComponent,
					itemCount: itemCount,
				},
			};
		};

		editor.windowManager.open(getDialogConfig(html, selectedComponent, itemCount));
	};

	const insertComponent = function (componentName, count) {
		const id = Date.now();
		let html = '';

		switch (componentName) {
			case 'accordion':
				html = `
          <div class="accordion" id="accordion-${id}">
            ${Array(count)
				.fill('')
				.map(
					(_, index) => `
              <div class="accordion-item">
                <h2 class="accordion-header" id="heading-${id}-${index}">
                  <button class="accordion-button ${
						index !== 0 ? 'collapsed' : ''
					}" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-${id}-${index}" aria-expanded="${
						index === 0 ? 'true' : 'false'
					}" aria-controls="collapse-${id}-${index}">
                    Accordion Item #${index + 1}
                  </button>
                </h2>
                <div id="collapse-${id}-${index}" class="accordion-collapse collapse ${
						index === 0 ? 'show' : ''
					}" aria-labelledby="heading-${id}-${index}" data-bs-parent="#accordion-${id}">
                  <div class="accordion-body">
                    Content for accordion item #${index + 1} goes here.
                  </div>
                </div>
              </div>
            `
				)
				.join('')}
          </div>
        `;
				break;
			case 'tabs':
				html = `
          <ul class="nav nav-tabs" id="tabs-${id}" role="tablist">
            ${Array(count)
				.fill('')
				.map(
					(_, index) => `
              <li class="nav-item" role="presentation">
                <button class="nav-link ${
					index === 0 ? 'active' : ''
				}" id="tab-${id}-${index}" data-bs-toggle="tab" data-bs-target="#tabpane-${id}-${index}" type="button" role="tab" aria-controls="tabpane-${id}-${index}" aria-selected="${
						index === 0 ? 'true' : 'false'
					}">Tab ${index + 1}</button>
              </li>
            `
				)
				.join('')}
          </ul>
          <div class="tab-content" id="tabContent-${id}">
            ${Array(count)
				.fill('')
				.map(
					(_, index) => `
              <div class="tab-pane fade ${
					index === 0 ? 'show active' : ''
				}" id="tabpane-${id}-${index}" role="tabpanel" aria-labelledby="tab-${id}-${index}">
                Content for Tab ${index + 1} goes here.
              </div>
            `
				)
				.join('')}
          </div>
        `;
				break;
			case 'table':
				html = `
	              <table class="table">
	                <thead>
	                  <tr>
	                    <th scope="col">#</th>
	                    <th scope="col">First</th>
	                    <th scope="col">Last</th>
	                    <th scope="col">Handle</th>
	                  </tr>
	                </thead>
	                <tbody>
	                  <tr>
	                    <th scope="row">1</th>
	                    <td>Mark</td>
	                    <td>Otto</td>
	                    <td>@mdo</td>
	                  </tr>
	                  <tr>
	                    <th scope="row">2</th>
	                    <td>Jacob</td>
	                    <td>Thornton</td>
	                    <td>@fat</td>
	                  </tr>
	                </tbody>
	              </table>
	            `;
				break;
			case 'card':
				html = `
	              <div class="card" style="width: 18rem;">
	                <img src="https://placehold.co/150" class="card-img-top" alt="...">
	                <div class="card-body">
	                  <h5 class="card-title">Card title</h5>
	                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
	                  <a href="#" class="btn btn-primary">Go somewhere</a>
	                </div>
	              </div>
	            `;
				break;
			case 'carousel':
				html = `
          <div id="carousel-${id}" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              ${Array(count)
					.fill('')
					.map(
						(_, index) => `
                <button type="button" data-bs-target="#carousel-${id}" data-bs-slide-to="${index}" ${
							index === 0 ? 'class="active" aria-current="true"' : ''
						} aria-label="Slide ${index + 1}"></button>
              `
					)
					.join('')}
            </div>
            <div class="carousel-inner">
              ${Array(count)
					.fill('')
					.map(
						(_, index) => `
                <div class="carousel-item ${index === 0 ? 'active' : ''}">
                  <img src="https://via.placeholder.com/800x400?text=Slide+${
						index + 1
					}" class="d-block w-100" alt="Slide ${index + 1}">
                  <div class="carousel-caption d-none d-md-block">
                    <h5>Slide ${index + 1} label</h5>
                    <p>Some representative placeholder content for slide ${index + 1}.</p>
                  </div>
                </div>
              `
					)
					.join('')}
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carousel-${id}" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carousel-${id}" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        `;
				break;
			case 'navbar':
				html = `
	              <nav class="navbar navbar-expand-lg navbar-light bg-light">
	                <div class="container-fluid">
	                  <a class="navbar-brand" href="#">Navbar</a>
	                  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
	                    <span class="navbar-toggler-icon"></span>
	                  </button>
	                  <div class="collapse navbar-collapse" id="navbarNav">
	                    <ul class="navbar-nav">
	                      <li class="nav-item">
	                        <a class="nav-link active" aria-current="page" href="#">Home</a>
	                      </li>
	                      <li class="nav-item">
	                        <a class="nav-link" href="#">Features</a>
	                      </li>
	                      <li class="nav-item">
	                        <a class="nav-link" href="#">Pricing</a>
	                      </li>
	                    </ul>
	                  </div>
	                </div>
	              </nav>
	            `;
				break;
			case 'imageRight':
				html = `
	              <div class="row align-items-center">
	                <div class="col-md-8">
	                  <h2>Heading for Image on Right</h2>
	                  <p>This is some sample text that goes along with the heading. You can replace this with your own content. The image will appear on the right side of this text on larger screens.</p>
	                </div>
	                <div class="col-md-4">
	                  <img src="https://placehold.co/400x300" class="img-fluid" alt="Placeholder image">
	                </div>
	              </div>
	            `;
				break;
			case 'imageLeft':
				html = `
	              <div class="row align-items-center">
	                <div class="col-md-4">
	                  <img src="https://placehold.co/400x300" class="img-fluid" alt="Placeholder image">
	                </div>
	                <div class="col-md-8">
	                  <h2>Heading for Image on Left</h2>
	                  <p>This is some sample text that goes along with the heading. You can replace this with your own content. The image appears on the left side of this text on larger screens.</p>
	                </div>
	              </div>
	            `;
				break;
			case 'features':
				html = `
          <div class="container px-4 py-5" id="features-${id}">
            <h2 class="pb-2 border-bottom">Features</h2>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 py-5">
              ${Array(count)
					.fill('')
					.map(
						(_, index) => `
                <div class="col d-flex align-items-start">
                  <div class="icon-square text-bg-light d-inline-flex align-items-center justify-content-center fs-4 flex-shrink-0 me-3">
                    <svg class="bi" width="1em" height="1em"><use xlink:href="#feature-icon-${index + 1}"/></svg>
                  </div>
                  <div>
                    <h3 class="fs-2">Feature ${index + 1}</h3>
                    <p>Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words.</p>
                    <a href="#" class="btn btn-primary">
                      Call to action
                    </a>
                  </div>
                </div>
              `
					)
					.join('')}
            </div>
          </div>
        `;
				break;

			case 'team':
				html = `
          <div class="container py-5" id="team-${id}">
            <div class="row text-center pb-5">
              <div class="col-md-12">
                <h2 class="display-4 mb-4">Our Team</h2>
                <p class="lead mb-5">Meet the talented individuals behind our success.</p>
              </div>
            </div>
            <div class="row">
              ${Array(count)
					.fill('')
					.map(
						(_, index) => `
                <div class="col-lg-${12 / Math.min(count, 4)} col-md-6 mb-4">
                  <div class="card border-0 shadow">
                    <img src="https://via.placeholder.com/300x300?text=Team+Member+${
						index + 1
					}" class="card-img-top" alt="Team Member ${index + 1}">
                    <div class="card-body text-center">
                      <h5 class="card-title mb-0">Team Member ${index + 1}</h5>
                      <div class="card-text text-black-50">Position</div>
                      <div class="mt-3">
                        <a href="#" class="text-dark me-2"><i class="fab fa-facebook-f"></i></a>
                        <a href="#" class="text-dark me-2"><i class="fab fa-twitter"></i></a>
                        <a href="#" class="text-dark me-2"><i class="fab fa-linkedin-in"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
              `
					)
					.join('')}
            </div>
          </div>
        `;
				break;

			case 'hero':
				html = `
	              <div class="px-4 py-5 my-5 text-center">
	                <h1 class="display-5 fw-bold">Welcome to Our Website</h1>
	                <div class="col-lg-6 mx-auto">
	                  <p class="lead mb-4">Quickly design and customize responsive mobile-first sites with Bootstrap, the world's most popular front-end open source toolkit.</p>
	                  <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
	                    <button type="button" class="btn btn-primary btn-lg px-4 gap-3">Primary button</button>
	                    <button type="button" class="btn btn-outline-secondary btn-lg px-4">Secondary</button>
	                  </div>
	                </div>
	              </div>
	            `;
				break;

			case 'pricing':
				html = `
          <div class="container py-5" id="pricing-${id}">
            <div class="row text-center pb-5">
              <div class="col-md-12">
                <h2 class="display-4 mb-4">Pricing Plans</h2>
                <p class="lead mb-5">Choose the plan that best fits your needs.</p>
              </div>
            </div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-${Math.min(count, 4)} mb-3 text-center">
              ${Array(count)
					.fill('')
					.map(
						(_, index) => `
                <div class="col">
                  <div class="card mb-4 rounded-3 shadow-sm ${index === 1 ? 'border-primary' : ''}">
                    <div class="card-header py-3 ${index === 1 ? 'text-bg-primary border-primary' : ''}">
                      <h4 class="my-0 fw-normal">Plan ${index + 1}</h4>
                    </div>
                    <div class="card-body">
                      <h1 class="card-title pricing-card-title">$${
							(index + 1) * 10
						}<small class="text-body-secondary fw-light">/mo</small></h1>
                      <ul class="list-unstyled mt-3 mb-4">
                        <li>Feature 1</li>
                        <li>Feature 2</li>
                        <li>Feature 3</li>
                        <li>Feature 4</li>
                      </ul>
                      <button type="button" class="w-100 btn btn-lg ${
							index === 1 ? 'btn-primary' : 'btn-outline-primary'
						}">Choose plan</button>
                    </div>
                  </div>
                </div>
              `
					)
					.join('')}
            </div>
          </div>
        `;
				break;

			case 'imageIntro':
				// Determine column classes based on count
				const colClass = count <= 4 ? `col-md-${12 / count}` : 'col-md-3 col-lg-2';

				html = `
          <div class="container py-4 py-md-6" id="imageIntro-${id}">
            <div class="row mb-5">
              <div class="col-md-6 align-self-center">
                <div class="lc-block text-center">
                  <img class="img-fluid mb-4" src="https://placehold.co/600x400" style="" loading="lazy" width="350" height="350" alt="Introductory image">
                </div><!-- /lc-block -->
              </div><!-- /col -->
              <div class="col-md-6">
                <div class="lc-block">
                  <div editable="rich">
                    <h2>Viiision is here to support your success.</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse a lacus est. Etiam diam metus, lobortis non augue at, placerat viverra risus. Cras ornare faucibus laoreet.&nbsp;</p>
                    <p>Aenean vel nisi in ipsum congue fermentum et ut arcu. Proin leo diam, vulputate eu tellus ac, mattis cursus nunc. In aliquet erat ac eros congue maximus.&nbsp;</p>
                  </div>
                </div><!-- /lc-block -->
              </div><!-- /col -->
            </div>
            <div class="row mt-4 justify-content-center">
              ${Array(count)
					.fill('')
					.map(
						(_, index) => `
                <div class="${colClass} col-sm-6 text-center mb-4">
                  <div class="lc-block">
                    <img class="img-fluid mb-3" src="https://placehold.co/100" loading="lazy" width="92" height="92" style="height:10vh" alt="Feature icon ${
						index + 1
					}">
                  </div>
                  <div class="lc-block">
                    <div editable="rich">
                      <h4>Feature ${index + 1}</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.&nbsp;</p>
                    </div>
                  </div>
                </div>
              `
					)
					.join('')}
            </div>
          </div>
        `;
				break;

			case 'counter':
				html = `
          <div class="container px-4 py-5" id="counter-${id}">
            <div class="row g-4 py-5 row-cols-1 row-cols-md-2 row-cols-lg-${Math.min(count, 4)}">
              ${Array(count)
					.fill('')
					.map(
						(_, index) => `
                <div class="col">
                  <div class="card h-100 text-center">
                    <div class="card-body">
                      <h2 class="card-title display-4 fw-bold mb-3" id="counter-value-${id}-${index}">0</h2>
                      <p class="card-text fs-5">Statistic ${index + 1}</p>
                    </div>
                  </div>
                </div>
              `
					)
					.join('')}
            </div>
          </div>
          <script>
            (function() {
              function animateCounter(elementId, finalValue, duration = 2000) {
                let startTimestamp = null;
                const element = document.getElementById(elementId);
                const startValue = 0;
                const step = (timestamp) => {
                  if (!startTimestamp) startTimestamp = timestamp;
                  const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                  const currentValue = Math.floor(progress * (finalValue - startValue) + startValue);
                  element.textContent = currentValue.toLocaleString();
                  if (progress < 1) {
                    window.requestAnimationFrame(step);
                  }
                };
                window.requestAnimationFrame(step);
              }

              ${Array(count)
					.fill('')
					.map(
						(_, index) => `
                animateCounter('counter-value-${id}-${index}', ${Math.floor(Math.random() * 10000)});
              `
					)
					.join('')}
            })();
          </script>
        `;
				break;
			case 'faq':
				html = `
          <div class="container py-5" id="faq-${id}">
            <div class="row text-center pb-5">
              <div class="col-md-12">
                <h2 class="display-4 mb-4">Frequently Asked Questions</h2>
                <p class="lead mb-5">Find answers to common questions about our products and services.</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-8">
                <div class="accordion accordion-flush" id="faqAccordion-${id}">
                  ${Array(count)
						.fill('')
						.map(
							(_, index) => `
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="faqHeading-${id}-${index}">
                        <button class="accordion-button ${
							index !== 0 ? 'collapsed' : ''
						}" type="button" data-bs-toggle="collapse" data-bs-target="#faqCollapse-${id}-${index}" aria-expanded="${
								index === 0 ? 'true' : 'false'
							}" aria-controls="faqCollapse-${id}-${index}">
                          Question ${index + 1}
                        </button>
                      </h2>
                      <div id="faqCollapse-${id}-${index}" class="accordion-collapse collapse ${
								index === 0 ? 'show' : ''
							}" aria-labelledby="faqHeading-${id}-${index}" data-bs-parent="#faqAccordion-${id}">
                        <div class="accordion-body">
                          This is the answer to Question ${
								index + 1
							}. Replace this text with the actual answer. You can include multiple paragraphs, lists, or other HTML elements as needed.
                        </div>
                      </div>
                    </div>
                  `
						)
						.join('')}
                </div>
              </div>
            </div>
          </div>
        `;
				break;
		}
		editor.insertContent(html);
		editor.windowManager.close();
	};

	editor.ui.registry.addIcon(
		'ui-component-icon',
		`<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <path d="M2 4v16h20V4H2zm18 14H4v-6h16v6zm0-8H4V6h16v4z"/>
  <path d="M6 10v8h2v-8H6z"/>
</svg>`
	);

	editor.ui.registry.addButton('uiComponents', {
		icon: `ui-component-icon`,
		tooltip: 'Insert UI Components',
		onAction: function () {
			openDialog();
		},
	});

	editor.ui.registry.addMenuItem('uiComponents', {
		text: 'UI Components',
		onAction: function () {
			openDialog();
		},
	});

	return {
		getMetadata: function () {
			return {
				name: 'UI Components plugin',
				url: 'http://liiingo.com/ui-components-plugin',
			};
		},
	};
};

export default CustomUIComponents;
