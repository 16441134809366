import axiosInstance from '../../store/api-client';

const FileUploaderPlugin = (editor) => {
	// Declare a variable to store the file input element
	let fileInput;

	// Register a custom icon for the file upload button
	editor.ui.registry.addIcon(
		'file-upload-icon',
		'<svg width="24" height="24" viewBox="0 0 24 24"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z" fill="currentColor"/></svg>'
	);

	// Add a custom button to the editor's toolbar
	editor.ui.registry.addButton('fileupload', {
		icon: 'file-upload-icon',
		tooltip: 'Upload File',
		onAction: showUploadDialog,
	});

	// Function to display the upload dialog
	function showUploadDialog() {
		let selectedFile = null;
		let dropArea = null;
		let anchorText = '';

		// Configure the dialog
		const dialogConfig = {
			title: 'Upload File',
			body: {
				type: 'panel',
				items: [
					{
						type: 'htmlpanel',
						html: `
                            <div id="drop-area" style="border: 2px dashed #ccc; border-radius: 20px; width: 100%; min-height: 200px; display: flex; align-items: center; justify-content: center; flex-direction: column; cursor: pointer;">
                                <p>Drag & Drop files here or click to select</p>
                                <div id="preview" style="width: 100%; height: 150px; margin-top: 10px; display: flex; justify-content: center; align-items: center; overflow: hidden;"></div>
                            </div>
                        `,
					},
					{
						type: 'input',
						name: 'anchorText',
						label: 'Anchor Text (optional)',
					},
				],
			},
			buttons: [
				{
					type: 'cancel',
					text: 'Cancel',
				},
				{
					type: 'submit',
					text: 'Upload',
					primary: true,
				},
			],
			onSubmit: (api) => {
				if (selectedFile) {
					anchorText = api.getData().anchorText;
					handleFileUpload(selectedFile, anchorText);
					api.close();
				} else {
					editor.notificationManager.open({
						text: 'Please select a file to upload',
						type: 'warning',
					});
				}
			},
			onClose: () => {
				if (dropArea) {
					dropArea.removeEventListener('dragover', handleDragOver);
					dropArea.removeEventListener('drop', handleDrop);
					dropArea.removeEventListener('click', handleClick);
				}
			},
		};

		// Open the dialog
		editor.windowManager.open(dialogConfig);

		// Set up event listeners for the drop area
		setTimeout(() => {
			dropArea = document.getElementById('drop-area');
			if (dropArea) {
				dropArea.addEventListener('dragover', handleDragOver);
				dropArea.addEventListener('drop', handleDrop);
				dropArea.addEventListener('click', handleClick);
			}
		}, 100);

		// Event handler functions
		function handleDragOver(e) {
			e.preventDefault();
			e.stopPropagation();
		}

		function handleDrop(e) {
			e.preventDefault();
			e.stopPropagation();
			const files = e.dataTransfer.files;
			if (files.length) {
				handleFiles(files);
			}
		}

		function handleClick() {
			fileInput.click();
		}

		function handleFiles(files) {
			selectedFile = files[0];
			updatePreview(selectedFile);
		}

		// Function to update the preview of the selected file
		function updatePreview(file) {
			const preview = document.getElementById('preview');
			if (preview) {
				preview.innerHTML = '';
				if (file.type.startsWith('image/')) {
					const img = document.createElement('img');
					img.style.maxWidth = '100%';
					img.style.maxHeight = '100%';
					img.style.objectFit = 'cover';
					preview.appendChild(img);

					const reader = new FileReader();
					reader.onload = (e) => {
						if (typeof e.target.result === 'string') {
							img.src = e.target.result;
						} else {
							console.error('Unexpected result type from FileReader');
						}
					};
					reader.readAsDataURL(file);
				} else {
					const fileInfo = document.createElement('p');
					fileInfo.textContent = `Selected file: ${file.name}`;
					fileInfo.style.textAlign = 'center';
					fileInfo.style.wordBreak = 'break-word';
					preview.appendChild(fileInfo);
				}
			}
		}

		// Set up file input change event
		fileInput.onchange = (event) => {
			const files = event.target.files;
			if (files.length) {
				handleFiles(files);
			}
		};
	}

	async function handleFileUpload(file, anchorText) {
		try {
			const formData = new FormData();
			formData.append('file', file);

			const response = await axiosInstance.post('/content/uploadFile', formData);

			if (response.status === 200) {
				const { url, name } = response.data;
				const fileExtension = name.split('.').pop().toLowerCase();

				let content = '';
				if (['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(fileExtension)) {
					content = `<img src="${url}" style="width: 100%; object-fit: contain" alt="${name}" />`;
				} else {
					const displayText = anchorText || name;
					content = `<a href="${url}" target="_blank">${displayText}</a>`;
				}

				editor.insertContent(content);
				editor.notificationManager.open({
					text: 'File uploaded successfully',
					type: 'success',
				});
			} else {
				throw new Error('Upload failed');
			}
		} catch (error) {
			console.error('Error uploading file:', error);
			editor.notificationManager.open({
				text: 'Error uploading file',
				type: 'error',
			});
		}
	}

	// Initialize the file input element when the editor is ready
	editor.on('init', () => {
		fileInput = document.createElement('input');
		fileInput.type = 'file';
		fileInput.style.display = 'none';
		fileInput.accept = 'image/*,.pdf,.doc,.docx,.txt';
		editor.getContainer().appendChild(fileInput);
	});

	// Return metadata for the plugin
	return {
		getMetadata: () => ({
			name: 'File Upload Plugin',
			url: 'https://liiingo.com/file-upload-plugin',
		}),
	};
};

export default FileUploaderPlugin;
