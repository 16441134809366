// TinyMCE plugin for importing Bootstrap HTML
const CustomHtmlPlugin = (editor) => {
	// Add Bootstrap CSS to the editor if not already included
	editor.on('init', function () {
		if (!editor.dom.styleSheetLoader) {
			editor.dom.loadCSS('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css');
		}
	});

	// Register a custom icon for the plugin
	editor.ui.registry.addIcon(
		'bootstrap-icon',
		'<svg width="24" height="24" viewBox="0 0 24 24"><path d="M5.136 0C3.13 0 2 1.137 2 3.145v17.71C2 22.863 3.13 24 5.136 24h13.728C20.87 24 22 22.863 22 20.855V3.145C22 1.137 20.87 0 18.864 0H5.136zM6.42 5.055h6.81c1.23 0 2.21.326 2.937.978.727.652 1.09 1.528 1.09 2.627 0 .714-.17 1.34-.513 1.88-.342.537-.838.954-1.486 1.248v.046c.854.247 1.5.702 1.94 1.366.44.664.66 1.478.66 2.44 0 1.163-.41 2.1-1.232 2.81-.82.71-1.925 1.064-3.316 1.064H6.42V5.055zm2.677 4.694v3.338h3.18c.663 0 1.18-.165 1.55-.495.37-.33.556-.787.556-1.373 0-1.14-.69-1.71-2.073-1.71l-3.213.24zm0 5.813v3.675h3.392c.702 0 1.24-.174 1.618-.52.377-.348.566-.84.566-1.476 0-.615-.194-1.082-.582-1.4-.388-.32-.936-.48-1.645-.48h-3.35z" fill="currentColor"/></svg>'
	);

	// Add a button to the toolbar
	editor.ui.registry.addButton('customhtml', {
		icon: 'bootstrap-icon',
		tooltip: 'Custom HTML',
		onAction: function () {
			openImportDialog();
		},
	});

	function openImportDialog() {
		editor.windowManager.open({
			title: 'Custom HTML',
			size: 'large',
			body: {
				type: 'panel',
				items: [
					{
						type: 'textarea',
						name: 'html',
						label: 'Paste your Bootrap HTML here',
						placeholder: '<div class="container">...</div>',
						maximized: true,
					},
				],
			},
			buttons: [
				{
					type: 'cancel',
					text: 'Cancel',
				},
				{
					type: 'submit',
					text: 'Import',
					primary: true,
				},
			],
			onSubmit: function (api) {
				var data = api.getData();
				var html = data.html;

				// Sanitize the HTML
				var sanitizedHtml = sanitizeHtml(html);

				// Insert the sanitized HTML into the editor
				editor.insertContent(sanitizedHtml);

				// Initialize any Bootstrap JavaScript components
				initializeBootstrapComponents();

				api.close();
			},
		});
	}

	function sanitizeHtml(html) {
		// This is a placeholder for a more robust sanitization function
		// In a real implementation, you'd use a library like DOMPurify
		// For now, we'll do some basic sanitization
		var tempDiv = document.createElement('div');
		tempDiv.innerHTML = html;

		// Remove any script tags
		var scripts = tempDiv.getElementsByTagName('script');
		for (var i = scripts.length - 1; i >= 0; i--) {
			scripts[i].parentNode.removeChild(scripts[i]);
		}

		return tempDiv.innerHTML;
	}

	function initializeBootstrapComponents() {
		// Instead of directly initializing Bootstrap components,
		// we'll add attributes that our custom handler will use
		editor.dom.select('[data-bs-toggle="tooltip"]').forEach(function (el) {
			el.setAttribute('data-mce-bootstrap-tooltip', 'true');
		});

		// Add more attribute setting for other Bootstrap components as needed
	}

	// Add custom CSS to style Bootstrap components within the editor
	editor.on('init', function () {
		var customCSS = `
      .mce-content-body .row { border: 1px dashed #ccc; padding: 5px; }
      .mce-content-body [class^="col-"] { border: 1px solid #ddd; padding: 5px; }
    `;
		editor.dom.addStyle(customCSS);
	});

	// Handle Bootstrap responsive classes in different TinyMCE view modes
	editor.on('ResizeEditor', function () {
		// This is where you'd adjust how Bootstrap responsive classes are displayed
		// based on the current editor size
	});

	// Add a custom handler for Bootstrap components
	editor.on('mouseenter', function (e) {
		var target = e.target;
		if (target.getAttribute('data-mce-bootstrap-tooltip') === 'true') {
			// Here you would implement custom tooltip behavior
			// For example, you could show a TinyMCE tooltip:
			editor.execCommand('mceShowTooltip', false, {
				content: target.getAttribute('title') || 'Tooltip',
				target: target,
			});
		}
		// Add more handlers for other Bootstrap components as needed
	});

	editor.on('mouseleave', function (e) {
		var target = e.target;
		if (target.getAttribute('data-mce-bootstrap-tooltip') === 'true') {
			// Hide the tooltip
			editor.execCommand('mceHideTooltip');
		}
		// Add more handlers for other Bootstrap components as needed
	});

	return {
		getMetadata: function () {
			return {
				name: 'Bootstrap Import',
				url: 'https://example.com/docs/bootstrapimport',
			};
		},
	};
};

export default CustomHtmlPlugin;
